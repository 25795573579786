














































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Home from './Home.vue'
import Sidebar from './Sidebar.vue'
import Create from './Create.vue'
import Edit from './Edit.vue'
import gql from 'graphql-tag'
import * as monaco from 'monaco-editor'

@Component({
  components: {
    Home,
    Sidebar,
    Create,
    Edit
  },
  apollo: {
    typescriptDefinitions: {
      query: gql`
        query typescriptDefinitions($environmentId: ID) {
          typescriptDefinitions(environmentId: $environmentId)
        }
      `,
      variables() {
        return {
          environmentId: this.environmentId
        }
      }
    }
  }
})
export default class TaskEditor extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String }) componentId?: String

  drawer = false
  lastUpdated = Date.now()
  typescriptDefinitions: string = ''

  @Watch('typescriptDefinitions')
  updateTypescriptDefinitions(tsd: string) {
    monaco.languages.typescript.javascriptDefaults.addExtraLib(
      tsd,
      '@simplexcl/codeless_database/index.d.ts'
    )
  }

  mounted() {
    //  Launch drawer if no component is selected
    if (this.$vuetify.breakpoint.mdAndUp || !this.componentId) {
      this.drawer = true
    }
  }

  handleUpdate() {
    this.lastUpdated = Date.now()
  }

  async handleError(e: any) {
    if (
      e.graphQLErrors &&
      e.graphQLErrors.some((ge: any) => ge.path?.includes('preview'))
    )
      return
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + e.message,
      color: 'error'
    })
  }
}
